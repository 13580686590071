import React, { useState, Fragment, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import LazyLoad from 'react-lazyload';

import { size } from '~/constants/Breakpoint';
import { getImage } from '~/helpers';
import axios from 'axios';
import { HeadingLarge, BodyTextAlt, Heading } from '~/components/.base/headings';
import { RedButton } from '~/components/.base/buttons';
import Slideshow from '~/components/.base/slideshow';
import no_image from '~/images/collectionList/noimage.jpeg';
import './MostSold.scss';
import ProductMarker from './ProductMarker';

import Modal from '~/components/.base/modal';
import { FlexBox, Col } from 'components/.base/containers';

const ProductThumb = styled.div`
  padding: 0 1rem;
  .img-cont {
    width: 100%;
    height: auto;
    border-radius: 30px;
    border: solid 1px #e2e4e4;
    margin: 0 1.5rem 1rem 0;
    img {
      width: 100%;
      border-radius: 30px;
    }
  }
  p {
    text-align: left;
    &.price {
      color: #d4212c;
    }
  }
`;

const MostSoldImageContainer = styled.div`
  position: relative;
`;

const MostSold = ({ showMostSold, mostSoldData, bgImages }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
  const [mostSoldArr, setMostSoldArr] = useState([]);

  const [isActive, setIsActive] = useState(0);

  const handleActiveItemChange = useCallback(i => {
    setIsActive(i);
  }, []);

  const mfProducts = useMemo(() => {
    let items = [
      {
        handle: mostSoldData.productHandle1,
        top: mostSoldData.productTop1,
        left: mostSoldData.productLeft1,
      },
      {
        handle: mostSoldData.productHandle2,
        top: mostSoldData.productTop2,
        left: mostSoldData.productLeft2,
      },
    ];

    if (mostSoldData.productHandle3) {
      items.push({
        handle: mostSoldData.productHandle3,
        top: mostSoldData.productTop3,
        left: mostSoldData.productLeft3,
      });
    }

    return items;
  }, [mostSoldData]);

  useEffect(() => {
    (async () => {
      if (mostSoldData && mfProducts.length > 0) {
        const mostSoldArr = [];

        for (let item of mfProducts) {
          const query = `
						query {
							productByHandle(handle: "${item.handle}") {
			                  images (first: 1) {
			                    edges {
			                      node {
									transformedSrc(maxWidth:600)
			                      }
			                    }
			                  }
			                  title
			                  handle
			                  variants (first: 1) {
			                    edges {
			                      node {
			                        price {
                                amount
                              }
			                      }
			                    }
			                  }
							}
						}
					`;

          const res = await axios.post(
            `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
            { query },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
              },
            }
          );

          if (res.data.data.productByHandle) {
            mostSoldArr.push({ ...item, node: res.data.data.productByHandle });
          }
        }

        const sortMostSoldArr = (a, b) => {
          const { top: top1Str = '0', left: left1Str = '0' } = a || {};
          const { top: top2Str = '0', left: left2Str = '0' } = b || {};

          const top1 = parseInt(top1Str, 10);
          const top2 = parseInt(top2Str, 10);

          const left1 = parseInt(left1Str, 10);
          const left2 = parseInt(left2Str, 10);

          return top1 === top2 ? left1 - left2 : top1 - top2;
        };

        setMostSoldArr(mostSoldArr.sort(sortMostSoldArr));
      }
    })();
  }, [mostSoldData, mfProducts]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return showMostSold ? (
    <div className="most-sold">
      {getImage(bgImages, 'blurred_smudge_3') && (
        <Image fluid={getImage(bgImages, 'blurred_smudge_3')} alt="" className="map-background" />
      )}
      {isMobile ? (
        <Modal className="most-sold-modal" open={modalIsOpen} onClose={() => setModalIsOpen(false)}>
          {mostSoldData.mainImage ? (
            <Image fluid={mostSoldData.mainImage.fluid} alt="" className="most-sold-image" />
          ) : null}
          <div className="modal-product-wrapper-container">
            <div className="modal-product-container">
              {mostSoldArr.map((item, i) => {
                const {
                  node: { images, title, handle, variants },
                } = item;
                return (
                  <div className="modal-product-item">
                    <Link key={i} to={`/products/${handle}/`}>
                      <ProductThumb key={i}>
                        <div className="img-cont">
                          {images && images.edges.length ? (
                            <LazyLoad height={506}>
                              <img src={images.edges[0].node.transformedSrc} alt="" />
                            </LazyLoad>
                          ) : (
                            <img src={no_image} alt="" />
                          )}
                        </div>
                        <BodyTextAlt>{title}</BodyTextAlt>
                        <Heading className="price">${Number(variants.edges[0].node.price.amount).toFixed(2)}</Heading>
                      </ProductThumb>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
      ) : null}
      {mostSoldData ? (
        <Fragment>
          <HeadingLarge as="h2" className="most-sold-title" md="0 0 2.5rem">
            Most <span className="red">Sold</span>
          </HeadingLarge>
          <FlexBox className="content">
            <div className="image">
              <MostSoldImageContainer>
                {mostSoldData.mainImage ? (
                  <Image fluid={mostSoldData.mainImage.fluid} alt="" className="most-sold-image" />
                ) : null}

                {mostSoldArr.map((item, i) => (
                  <ProductMarker
                    key={i}
                    productTitle={item?.node?.title || ''}
                    top={item.top}
                    left={item.left}
                    active={isActive === i ? true : false}
                    onClick={() => handleActiveItemChange(i)}
                  />
                ))}
              </MostSoldImageContainer>
            </div>
            <div className="contentSlider">
              {mostSoldArr && mostSoldArr.length ? (
                <Slideshow
                  carouselLabel="most sold"
                  currentSlide={isActive}
                  handleChange={handleActiveItemChange}
                >
                  {mostSoldArr.map((item, i) => {
                    const {
                      node: { images, title, handle, variants },
                    } = item;
                    return (
                      <Link key={i} to={`/products/${handle}`}>
                        <ProductThumb key={i}>
                          <div className="img-cont">
                            {images && images.edges.length ? (
                              <LazyLoad height={506}>
                                <img src={images.edges[0].node.transformedSrc} alt="" />
                              </LazyLoad>
                            ) : (
                              <LazyLoad height={506}>
                                <img src={no_image} alt="" />
                              </LazyLoad>
                            )}
                          </div>
                          <BodyTextAlt>{title}</BodyTextAlt>
                          <Heading className="price">${Number(variants.edges[0].node.price.amount).toFixed(2)}</Heading>
                        </ProductThumb>
                      </Link>
                    );
                  })}
                </Slideshow>
              ) : null}
            </div>
          </FlexBox>
          <FlexBox justify="center">
            <RedButton
              className="mobile"
              onClick={() => openModal()}
              aria-label="View most sold products"
            >
              View products
            </RedButton>
          </FlexBox>
        </Fragment>
      ) : null}
    </div>
  ) : null;
};

export default MostSold;
