import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { chunk } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

import { size } from '~/constants/Breakpoint';
import { getShopifyImageTransformedSrc } from '../../../helpers';
import { getImage } from '~/helpers';
import { HeadingLarge, BodyTextAlt, Heading } from '~/components/.base/headings';
import { FlexBox, Row, Col } from '~/components/.base/containers';
import Slideshow from '~/components/.base/slideshow';
import no_image from '~/images/collectionList/noimage.jpeg';
import './WeeklySelection.scss';

const ProductItem = ({ productItem }) => {
  const {
    node: { images, title, handle, variants },
  } = productItem;
  const imgSrc =
    images && images.edges.length
      ? images.edges[0].node.transformedSrc
        ? images.edges[0].node.transformedSrc
        : getShopifyImageTransformedSrc(images.edges[0].node.originalSrc, '_400x')
      : null;
  return (
    <Link to={`/products/${handle}`}>
      <FlexBox direction="column" justify="space-between" align="center" className="product-tile">
        <div className="img-cont img-cont--default">
          {imgSrc ? <img src={imgSrc} alt={title} /> : <img src={no_image} alt={title} />}
        </div>
        <div className="details">
          <BodyTextAlt>{title}</BodyTextAlt>
          <Heading>
            <span className="red">
              <span className="dollar-sign">$</span>
              {Number(variants.edges[0].node.price.amount).toFixed(2)}
            </span>
          </Heading>
        </div>
      </FlexBox>
    </Link>
  );
};

const WeeklySelection = ({ showWeeklySelection, metafieldData, bgImages }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
  const [weeklySelectionArr, setWeeklySelectionArr] = useState([]);
  useEffect(() => {
    (async () => {
      if (metafieldData && metafieldData.length) {
        let mfProducts = [];
        for (let item of metafieldData) {
          const query = `
            query {
              productByHandle(handle: "${item}") {
                images (first: 1) {
                  edges {
                    node {
                      transformedSrc(maxWidth:600)
                    }
                  }
                }
                title
                handle
                variants (first: 1) {
                  edges {
                    node {
                      price {
                        amount
                      }
                    }
                  }
                }
              }
            }
          `;
          const res = await axios.post(
            `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
            { query },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
              },
            }
          );
          if (!res.data.data.productByHandle) {
          } else {
            mfProducts.push({
              node: res.data.data.productByHandle,
            });
          }
        }
        setWeeklySelectionArr(mfProducts);
      }
    })();
  }, [metafieldData]);

  return showWeeklySelection && weeklySelectionArr?.length > 0 ? (
    <div style={{ position: 'relative' }}>
      <Image
        fluid={getImage(bgImages, 'blurred_smudge')}
        alt="border"
        className="map-background-ws"
        style={{ zIndex: -2 }}
      />
      <Row className={`weekly-selection ${isMobile ? 'mobile' : ''}`} justify="space-between">
        <Col size={1}>
          <HeadingLarge as="h2" className="weekly-selection-title">
            Our
            <span className="red"> weekly selection</span>
          </HeadingLarge>
        </Col>
        <Col className="slideshow-col desktop" size={4}>
          <Slideshow carouselLabel="Our weekly selection">
            {chunk(weeklySelectionArr, 3).map((productSubGroup, i) =>
              i < 9 ? (
                <ul className="product-grid" key={i}>
                  {productSubGroup.map((product, i) => (
                    <li key={i} role="group" aria-label="product">
                      <ProductItem productItem={product} />
                    </li>
                  ))}
                </ul>
              ) : (
                false
              )
            )}
          </Slideshow>
        </Col>
        <Col className="slideshow-col mobile" size={4}>
          <Slideshow carouselLabel="Weekly selection">
            {weeklySelectionArr && weeklySelectionArr.length
              ? weeklySelectionArr
                  .slice(0, 3)
                  .filter(item => item && item.node)
                  .map((product, i) => <ProductItem key={i} productItem={product} />)
              : null}
          </Slideshow>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default WeeklySelection;
