import React, { useCallback, useEffect, useRef, useState } from 'react';
import Image from 'gatsby-image';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { getImage } from '~/helpers';
import { css } from 'styled-components';
import './EcommerceHomePageVideo.scss';
import { WoodenBorder } from 'components/.base/border';
import PlayButton from '~/images/icons/mobileApp/play-btn.svg';
import PauseButton from '~/images/icons/mobileApp/pause-btn.svg';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
}

const EcommerceHomePageVideo = ({ bgImages, ecommImages, videoLink }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(null);
  const [canPlayVideo, setCanPlayVideo] = useState(false);

  const videoWrapperRef = useRef(null);

  const toggleVideoPlaying = useCallback(() => {
    if (videoWrapperRef?.current?.querySelector('video')) {
      const videoElm = videoWrapperRef.current.querySelector('video');
      if (isVideoPlaying) {
        videoElm.pause();
        return;
      }
      if (canPlayVideo) {
        videoElm.play();
        return;
      }
    }
  }, [videoWrapperRef, isVideoPlaying, canPlayVideo]);

  useEffect(() => {
    if (videoWrapperRef?.current?.querySelector('video')) {
      const videoElm = videoWrapperRef.current.querySelector('video');
      const handleOnPlay = () => {
        setIsVideoPlaying(true);
      };
      const handleOnPause = () => {
        setIsVideoPlaying(false);
      };
      const handleOnCanPlay = () => {
        setCanPlayVideo(true);
      };
      const handleOnLoadedData = () => {
        setCanPlayVideo(true);
      };
      videoElm.addEventListener('play', handleOnPlay);
      videoElm.addEventListener('pause', handleOnPause);
      videoElm.addEventListener('canplay', handleOnCanPlay);
      videoElm.addEventListener('loadeddata', handleOnLoadedData);
      return () => {
        videoElm.removeEventListener('play', handleOnPlay);
        videoElm.removeEventListener('pause', handleOnPause);
        videoElm.removeEventListener('canplay', handleOnCanPlay);
        videoElm.removeEventListener('loadeddata', handleOnLoadedData);
      };
    }
  }, [videoWrapperRef.current, setIsVideoPlaying, setCanPlayVideo]);

  // Play/pause video when it enters or leaves the screen
  useEffect(() => {
    let st;
    if (videoWrapperRef?.current?.querySelector('video')) {
      st = ScrollTrigger.create({
        trigger: videoWrapperRef.current,
        start: 'top 60%',
        end: 'bottom 20%',
        invalidateOnRefresh: true,
        onToggle: ({ isActive }) => {
          const videoElm = videoWrapperRef.current.querySelector('video');
          if (isActive) {
            videoElm.play();
          } else {
            videoElm.pause();
          }
        },
      });
    }
    return () => {
      if (st) {
        st.kill();
      }
    };
  }, [videoWrapperRef]);

  return (
    <div
      css={css`
        position: relative;
        @media screen and (min-width: 951px) {
          overflow: hidden;
        }
      `}
    >
      {getImage(ecommImages, 'lobster-tails') && (
        <Image fluid={getImage(ecommImages, 'lobster-tails')} alt="" className="lobster-tails" />
      )}
      <div className="ecommerce-home-page-video">
        <WoodenBorder zIndex="1" />
        {videoLink ? (
          <>
            <button
              onClick={toggleVideoPlaying}
              className={`videoControls ${isVideoPlaying ? 'pause' : 'play'}`}
            >
              <span className="sr-only">{isVideoPlaying ? 'Pause video' : 'Play video'}</span>
              {isVideoPlaying ? <PauseButton /> : <PlayButton />}
            </button>
            <div
              ref={videoWrapperRef}
              dangerouslySetInnerHTML={{
                __html: `<video
                              width="100%"
                              height="auto"
                              preload="metadata"
                              muted
                              loop
                              playsinline
                              disablePictureInPicture
                          >
                              <source src="${videoLink}#t=0.001" type="video/mp4" />
                              Sorry, your browser does not support embedded videos.
                          </video>`,
              }}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default EcommerceHomePageVideo;
