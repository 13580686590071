import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { Carousel } from 'react-responsive-carousel';
import { getImage } from 'helpers';
import { FlexBox, Col, CenteredContainer } from 'components/.base/containers';
import { HeadingLarge, Subheading, Heading } from 'components/.base/headings';
import { RedButton, WhiteButton } from 'components/.base/buttons';
import ArrowRight from 'images/icons/slideshow_arrow_right.svg';
import ArrowLeft from 'images/icons/slideshow_arrow_left.svg';
import './Recipes.scss';

const Recipes = ({ showRecipes, recipeData, bgImages }) => {
  return showRecipes ? (
    <div className="recipes">
      <Image
        fluid={getImage(bgImages, 'blurred_smudge_4')}
        alt="border"
        className="map-background"
      />
      <Image
        fluid={getImage(bgImages, 'blurred_smudge_2')}
        alt="border"
        className="mobile-map-background"
      />
      <Subheading>COOKING WITH THE COUSINS</Subheading>
      <HeadingLarge as="h2" className="recipes-title" md="0 0 2.5rem">
        <span className="red">Recipes</span> so easy even Sabin can do it
      </HeadingLarge>
      <FlexBox wrap="wrap" className="content">
        {recipeData.map((prod, i) => (
          <Col size="0 0 25%" maxWidth="25%">
            <Link to="/recipes" className="recipe-link">
              <CenteredContainer>
                <div className="image-cont">
                  <Image fluid={prod.poster.fluid} alt="" />
                </div>
                <WhiteButton as="span" className="recipe-plus" h="40px" w="40px" pd="0">
                  +
                </WhiteButton>
              </CenteredContainer>
              <Heading>{prod.title}</Heading>
            </Link>
          </Col>
        ))}
      </FlexBox>
      <FlexBox className="content mobile">
        <Carousel
          renderArrowPrev={(clickHandler, hasPrev) =>
            hasPrev ? (
              <WhiteButton onClick={clickHandler} className="prev-next prev">
                <ArrowLeft />
              </WhiteButton>
            ) : null
          }
          renderArrowNext={(clickHandler, hasNext) =>
            hasNext ? (
              <WhiteButton onClick={clickHandler} className="prev-next next">
                <ArrowRight />
              </WhiteButton>
            ) : null
          }
          renderIndicator={() => null}
          renderThumbs={() => null}
        >
          {recipeData.map((prod, i) => (
            <Col size={1}>
              <Link to="/recipes">
                <CenteredContainer>
                  <div className="image-cont">
                    <Image fluid={prod.poster.fluid} alt={prod.title} />
                  </div>
                  <WhiteButton as="span" className="recipes-plus" h="40px" w="40px" pd="0">
                    +
                  </WhiteButton>
                </CenteredContainer>
                <Heading>{prod.title}</Heading>
              </Link>
            </Col>
          ))}
        </Carousel>
      </FlexBox>
      <FlexBox className="see-all" justify="center">
        <RedButton as={Link} to="/recipes">
          See all recipes
        </RedButton>
      </FlexBox>
    </div>
  ) : null;
};

export default Recipes;
