import React, { useState, useEffect, useContext, useMemo } from 'react';
import { graphql } from 'gatsby';
import StoreContext from '~/layouts/StoreContext';

import { Criteo } from 'components/Criteo/Criteo';
import SEO from 'components/seo';
import meta from '~/constants/meta';
import { DeriveHeaderClasses } from 'components/Navigation';
import { Hero } from 'components/HomePage';
import './shop.scss';
import { WoodenBorder } from 'components/.base/border';
import MailingListPopup from 'components/JoinMailingList/MailingListPopup';
import {
  EcommerceHomeSlideshow,
  FeaturedCollection,
  MostSold,
  ProductRange,
  Recipes,
  WeeklySelection,
} from 'components/EcommerceHomePage';
import { getShopMetafields } from 'helpers/requests/metafield-requests';
const EcommerceHomepage = ({ data, location }) => {
  const {
    setHeaderWhite,
    store: { whiteHeader },
  } = useContext(StoreContext);

  const [metafieldData, setMetafieldData] = useState([]);

  const showWeeklySelection = useMemo(() => false, []);

  const memorizedData = useMemo(() => {
    const { contentfulPage, ecommImages, bgImages } = { ...data };

    const productRangeModule =
      contentfulPage?.contentModules?.find(m => m.title === 'Product Range') || null;
    const featuredCollectionModule = contentfulPage?.contentModules?.find(
      m => m.__typename === 'ContentfulEcommercePageFeaturedCollectionSection'
    );
    const mostSoldModule =
      contentfulPage?.contentModules?.find(
        m => m.__typename === 'ContentfulEcommercePageMostSoldSection'
      ) || null;
    const recipesModule =
      contentfulPage?.contentModules?.find(
        m => m.__typename === 'ContentfulEcommercePageRecipeSection'
      ) || null;
    const slideshowData =
      contentfulPage?.contentModules?.find(
        m => m.__typename === 'ContentfulFranchisePageQuotesSection'
      ) || null;

    return {
      slideData:
        contentfulPage?.contentModules?.find(m => m.title === 'Ecommerce Hero')?.heroSlides || [],
      showProductRange: productRangeModule?.showThisBlock || false,
      productRangeData: productRangeModule?.collectionTiles || [],
      showFeaturedCollection: featuredCollectionModule?.showThisBlock || false,
      featuredCollectionData: featuredCollectionModule || null,
      showMostSold: mostSoldModule.showThisBlock || false,
      mostSoldData: mostSoldModule || null,
      showRecipes: recipesModule?.showThisBlock || false,
      recipes: recipesModule?.recipes || [],
      videoLink:
        contentfulPage?.contentModules?.find(m => m.__typename === 'ContentfulYoutubeVideo')
          ?.videoLink || null,
      imageData: ecommImages?.edges || [],
      bgImageData: bgImages?.edges || [],
      slideshowData,
    };
  }, [data]);

  console.log(memorizedData.slideshowData);

  useEffect(() => {
    if (!whiteHeader) {
      setHeaderWhite(true);
    }
  }, [whiteHeader]);

  // TODO CAN REMOVE THIS IF MOST SOLD STAYS IN CONTENTFUL
  useEffect(() => {
    if (showWeeklySelection) {
      getShopMetafields()
        .then(res => {
          console.log(res);
          if (res.data.data) {
            if (
              res.data.data.shop.metafields.edges.find(
                mf => mf.node.key === 'weekly_selection_products'
              )
            ) {
              setMetafieldData(
                JSON.parse(
                  res.data.data.shop.metafields.edges.find(
                    mf => mf.node.key === 'weekly_selection_products'
                  ).node.value
                )
              );
            }
          }
        })
        .catch(err => console.log(err));
    }
  }, []);

  return (
    <div className="ecommerce-homepage">
      <MailingListPopup />
      <SEO title="Collections" meta={[meta.google.siteVerification]} />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <div className={`ecommerce-hero`}>
        <Hero
          hideParallax={true}
          homepageImages={memorizedData.imageData}
          slideData={memorizedData.slideData}
        />
        <WoodenBorder top="100%" zIndex="1" />
      </div>
      <ProductRange
        ecommImages={memorizedData.imageData}
        showProductRange={memorizedData.showProductRange}
        productRangeData={memorizedData.productRangeData}
      />
      <WeeklySelection
        bgImages={memorizedData.bgImageData}
        showWeeklySelection={showWeeklySelection}
        metafieldData={metafieldData}
      />
      <FeaturedCollection
        ecommImages={memorizedData.imageData}
        showFeaturedCollection={memorizedData.showFeaturedCollection}
        featuredCollectionData={memorizedData.featuredCollectionData}
      />
      <MostSold
        bgImages={memorizedData.bgImageData}
        showMostSold={memorizedData.showMostSold}
        mostSoldData={memorizedData.mostSoldData}
      />
      <EcommerceHomeSlideshow sectionData={memorizedData.slideshowData} />
      {/* <EcommerceHomePageVideo
        ecommImages={memorizedData.imageData}
        bgImages={memorizedData.bgImageData}
        videoLink={memorizedData.videoLink}
      /> */}
      <Recipes
        bgImages={memorizedData.bgImageData}
        showRecipes={memorizedData.showRecipes}
        recipeData={memorizedData.recipes}
      />
    </div>
  );
};

export const query = graphql`
  query {
    contentfulPage(title: { eq: "Ecommerce Page" }) {
      title
      contentModules {
        ... on ContentfulHeroSection {
          title
          heroSlides {
            title
            slideImage {
              fluid(maxWidth: 1600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            mobileImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            buttonText
            buttonLink
            whiteHeaderText
            useOverlay
            overlayOpacity
          }
        }
        ... on ContentfulEcommercePageProductRangeSection {
          showThisBlock
          title
          collectionTiles {
            title
            handle
            image {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyContentfulFluid_withWebp
              }
              fixed(width: 175, height: 175) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
        ... on ContentfulEcommercePageFeaturedCollectionSection {
          showThisBlock
          title
          handle
          collectionTitle
          ctaText
          collectionImage {
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        ... on ContentfulEcommercePageRecipeSection {
          showThisBlock
          title
          recipes {
            title
            poster {
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        ... on ContentfulEcommercePageMostSoldSection {
          showThisBlock
          productHandle1
          productTop1
          productLeft1
          productHandle2
          productTop2
          productLeft2
          productHandle3
          productTop3
          productLeft3
          mainImage {
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        # ... on ContentfulYoutubeVideo {
        #   videoLink
        # }
        ... on ContentfulFranchisePageQuotesSection {
          title
          heading
          franchisePageQuotesSectionQuoteBlock {
            quotation
            author
            mediaMobile {
              description
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            media {
              description
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
    ecommImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/ecommercepage/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1600, quality: 70) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }

    bgImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/background/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default EcommerceHomepage;
