import React from 'react';
import { css } from 'styled-components';
import Image from 'gatsby-image';
import { useMediaQuery } from 'react-responsive';

import { HeadingLarge, Subheading } from '~/components/.base/headings';
import Slideshow from 'components/.base/slideshow';

import { breakpoint, size } from '../../../constants/Breakpoint';
import { WoodenBorder } from '../../.base/border';
import { Col, Row } from 'components/.base/containers';

const EcommerceHomeSlideshow = ({ sectionData }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
  return sectionData?.franchisePageQuotesSectionQuoteBlock ? (
    <div className={`ecommHome-slideshow ${isMobile ? 'mobile' : ''}`}>
      {sectionData.heading && (
        <Row
          css={css`
            padding: 60px 0;
          `}
        >
          {showStickyMenu && !isMobile && <Col size={1} className="stickySidebar" />}
          <Col size={4}>
            <HeadingLarge
              as="h2"
              css={css`
                padding: 0 20px;
                @media ${breakpoint.lg} {
                  padding: 0 2.5rem;
                }
              `}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: sectionData.heading,
                }}
              ></span>
            </HeadingLarge>
          </Col>
        </Row>
      )}
      <div
        css={css`
          position: relative;
          @media ${breakpoint.maxLg} {
            .carousel {
              .control-dots .circle .inner {
                background: rgba(255, 255, 255, 1);
              }
            }
          }
          @media ${breakpoint.maxMd} {
            .carousel {
              .control-dots {
                display: none;
              }
            }
          }
        `}
      >
        <WoodenBorder top="0" zIndex="1" />
        <WoodenBorder top="100%" zIndex="1" />
        <Slideshow
          carouselLabel={sectionData.title}
          noLoop={false}
          interval={5000}
          arrowPosition={{
            top: 'auto',
            bottom: '70px',
            prev: {
              right: 'auto',
              left: '40px',
            },
            next: {
              right: 'auto',
              left: '80px',
            },
          }}
          dotPosition={{
            top: 'auto',
            bottom: '70px',
            right: 'auto',
            left: '140px',
            width: 'auto',
          }}
        >
          {sectionData.franchisePageQuotesSectionQuoteBlock.map((block, i) => (
            <div key={i}>
              <div
                className="slide-img"
                css={css`
                  height: 40vh;
                  min-height: 400px;
                  background-size: cover;
                  display: flex;
                  text-align: right;
                  align-items: flex-end;
                  justify-content: flex-end;
                  padding: 90px 40px;
                  @media ${breakpoint.lg} {
                    height: 80vh;
                    min-height: 800px;
                  }
                  .gatsby-image-wrapper {
                    position: absolute !important; // overwrite gatsby style
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 100%;
                    width: 100%;
                  }
                `}
              >
                {isMobile && block.mediaMobile ? (
                  <Image fluid={block.mediaMobile.fluid} alt={block.mediaMobile.description} />
                ) : (
                  <Image fluid={block.media.fluid} alt={block.media.description} />
                )}
              </div>
              {(block.quotation || block.author) && (
                <div
                  aria-hidden="true"
                  css={css`
                    width: 100%;
                    margin-top: 90px;
                    text-align: left;
                    padding: 0 20px;
                    visibility: hidden;
                    @media ${breakpoint.lg} {
                      margin-top: 0;
                      position: absolute;
                      padding: 0 40px;
                      right: 0;
                      bottom: 90px;
                      text-align: right;
                      max-width: 960px;
                    }
                  `}
                >
                  {block.quotation && (
                    <HeadingLarge
                      as="h3"
                      className="slide-title"
                      md="0 0 2rem"
                      css={css`
                        @media ${breakpoint.maxLg} {
                          font-size: 24px;
                          margin: 0 0 15px;
                        }
                      `}
                    >
                      <span
                        css={css`
                          @media ${breakpoint.lg} {
                            color: #fff;
                          }
                        `}
                        dangerouslySetInnerHTML={{
                          __html: block.quotation,
                        }}
                      ></span>
                    </HeadingLarge>
                  )}
                  {block.author && (
                    <Subheading
                      as="h4"
                      css={css`
                        color: #5c6566;
                        @media ${breakpoint.lg} {
                          color: #fff;
                        }
                      `}
                    >
                      — {block.author}
                    </Subheading>
                  )}
                </div>
              )}
            </div>
          ))}
        </Slideshow>
      </div>
    </div>
  ) : (
    ''
  );
};

export default EcommerceHomeSlideshow;
