import React from "react";
import styled from "styled-components";

const OuterOval = styled.button`
  width: 60px;
  height: 60px;
  padding: 24px;
  border: solid 1px #ffffff;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  cursor: pointer;

  &:focus:not(:disabled) {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(105, 22, 26, 0.75);
  }
`;

const InnerOval = styled.div`
  width: 12px;
  height: 12px;
  border: 3px solid #ffffff;
  background-color: transparent;
  border-radius: 50%;
`;

const ProductMarker = ({ top, left, active, onClick, productTitle }) => {
  return (
    <OuterOval
      style={{
        top: top + "%",
        left: left + "%"
      }}
      onClick={onClick}
      className={`${active ? "active-marker" : ""} outer-oval`}
      aria-label={`View ${productTitle}`}
    >
      <InnerOval className="inner-oval" />
    </OuterOval>
  );
};

export default ProductMarker;
