import React, { Fragment, useEffect } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { css } from 'styled-components';

import { getImage } from '~/helpers';
import {
  BackgroundDivBgImage,
  FlexBox,
  Col,
  CenteredContainer,
} from '~/components/.base/containers';
import { HeadingLarge, LargeStencil } from '~/components/.base/headings';
import { LargeRedButton } from '~/components/.base/buttons';
import './FeaturedCollection.scss';
import { WoodenBorder } from '../../.base/border';

const FeaturedCollection = ({ showFeaturedCollection, featuredCollectionData, ecommImages }) => {
  gsap.registerPlugin(ScrollTrigger);

  let scrollTriggers = [];
  useEffect(() => {
    if (document.body.contains(document.querySelector(`.featured-collection ${LargeStencil}`))) {
      let tl = gsap.timeline().to(`.featured-collection ${LargeStencil}`, {
        x: '0',
      });
      let st = ScrollTrigger.create({
        trigger: '.featured-collection',
        start: 'center center',
        end: 'center center-=55%',
        scrub: 0.5,
        animation: tl,
      });
      scrollTriggers.push(st);
    }

    // Specify how to clean up after this effect:
    return () => {
      // all garbage cleanup all goes in a single useEffect for when component leaves
      if (scrollTriggers) {
        scrollTriggers.map(st => {
          st.kill();
          return true;
        });
      }
    };
  }, [featuredCollectionData]);
  return showFeaturedCollection ? (
    <div className="featured-collection">
      <WoodenBorder top="50px" topMd="380px" zIndex="-1" />
      <WoodenBorder top="500px" topMd="1030px" zIndex="-1" />
      <BackgroundDivBgImage
        className="prod-range-bg"
        w="100%"
        top="380px"
        topMobile="50px"
        h="650px"
        heightMobile="450px"
        color="#edf2f2"
        style={{ zIndex: '-2' }}
      />
      {getImage(ecommImages, 'oranges') && (
        <Image fluid={getImage(ecommImages, 'oranges')} alt="" className="oranges" />
      )}
      {featuredCollectionData ? (
        <Fragment>
          <FlexBox justify="center">
            <HeadingLarge className="featured-collection-title" as="h2" md="0 0 2.5rem">
              SHOP OUR <br />
              <span className="red">{featuredCollectionData.collectionTitle}</span>
            </HeadingLarge>
          </FlexBox>
          <Link to={featuredCollectionData.handle} className="featuredCollection__link">
            <FlexBox className="content">
              <LargeStencil
                as="p"
                className="stencil"
                css={css`
                  right: 0;
                  top: 50%;
                  transform: translate(calc(4530px - 100vw), -50%);
                  font-size: 600px;
                `}
                disabled
              >
                {featuredCollectionData.collectionTitle}
              </LargeStencil>
              <Col size={1}>
                <CenteredContainer>
                  <div className="image-cont">
                    <Image fluid={featuredCollectionData.collectionImage.fluid} alt="" />
                  </div>
                </CenteredContainer>
              </Col>
            </FlexBox>
            <FlexBox className="see-all" justify="center">
              <LargeRedButton as="span">{featuredCollectionData.ctaText}</LargeRedButton>
            </FlexBox>
          </Link>
        </Fragment>
      ) : null}
    </div>
  ) : null;
};

export default FeaturedCollection;
