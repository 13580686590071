import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { getImage } from '~/helpers';
import {
  BackgroundDivBgImage,
  FlexBox,
  Col,
  CenteredContainer,
} from '~/components/.base/containers';
import { HeadingLarge, Heading } from '~/components/.base/headings';
import { LargeRedButton, WhiteButton } from '~/components/.base/buttons';
import './ProductRange.scss';
import { WoodenBorder } from 'components/.base/border';

const ProductRange = ({ productRangeData, bgImages, ecommImages }) => {
  return (
    <div className="product-range">
      <WoodenBorder top="50px" topMd="145px" zIndex="-1" />
      <WoodenBorder top="500px" topMd="795px" zIndex="-1" />
      <BackgroundDivBgImage
        className="prod-range-bg"
        w="100%"
        top="145px"
        topMobile="50px"
        h="650px"
        heightMobile="450px"
        color="#edf2f2"
        style={{ zIndex: '-2' }}
      />
      <Image fluid={getImage(ecommImages, 'seagull')} alt="" className="seagull" />
      <FlexBox justify="center">
        <HeadingLarge as="h2" className="product-range-title desktop" md="0 0 2.5rem">
          SHOP OUR{' '}
          <span className="red">
            RANGE OF <br />
            PRODUCTS
          </span>
        </HeadingLarge>
        <HeadingLarge as="h2" className="product-range-title mobile" md="0 0 2.5rem">
          SHOP OUR <br />
          <span className="red">
            RANGE OF <br />
            PRODUCTS
          </span>
        </HeadingLarge>
      </FlexBox>
      <h2 className='sr-only'>Shop our range of products</h2>
      <FlexBox className="content" as="ul">
        {productRangeData.map((prod, i) => (
          <Col key={i} size={1} as="li">
            <Link to={prod.handle} className="productItem">
              <CenteredContainer>
                <div className="image-cont">
                  {prod?.image?.fluid && (
                    <Image fluid={prod.image.fluid} className="fluid" alt="" />
                  )}
                  {prod?.image?.fixed && (
                    <Image fixed={prod.image.fixed} className="fixed" alt="" />
                  )}
                </div>
                <WhiteButton as="span" className="product-range-plus" h="40px" w="40px" pd="0" aria-label="View more">
                  <span aria-hidden="true">+</span>
                </WhiteButton>
              </CenteredContainer>
              <Heading as="p" className="productItem__title">
                {prod.title}
              </Heading>
            </Link>
          </Col>
        ))}
      </FlexBox>
      <FlexBox className="see-all" justify="center">
        <LargeRedButton as={Link} to="/collection-list">
          See all collections
        </LargeRedButton>
      </FlexBox>
    </div>
  );
};

export default ProductRange;
